
/* Board Style - Video */
.board-list-video { width: 100%; }
.board-list-video .row{ margin:-10px -5px; }
.board-list-video .row [class*="col"]{ padding:10px 5px; }
.board-list-video__inner, .board-list-video >a{
    position: relative;
}
.board-list-video__inner{
    border-bottom:1px solid var(--v-primary-base);
}
.board-list-video__img-wrap{
    position: relative;
    width: 100%;
    padding-top: calc(210/380 * 100%);
    margin: auto;
    border:1px solid #efefef;
    overflow: hidden;
    background-color: #f5f5f5;
    background-repeat: no-repeat;
    background-position:center;
    background-size: cover;
    background-image:url(/images/common/noimg.png);
}
.board-list-video__img-wrap::before{
    content:'';
    position:absolute;
    top:50%;
    left:50%;
    z-index: 1;
    -webkit-transform: translate(-50%,-50%); -ms-transform: translate(-50%,-50%); transform: translate(-50%,-50%);
    display: block;
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-position:center;
    background-size: contain;
    background-image:url(/images/icon/icon-play.png);
}
.board-list-video__img{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%) scale(1);
    transform-origin: center;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position:center;
    background-size: cover;
    -webkit-transition: transform ease-out .5s; -ms-transition: transform ease-out .5s; transition: transform ease-out .5s;
    animation: FadeIn .4s ease-in-out .5s forwards;
    opacity: 0;
}
.board-list-video__img::before{
    content:'';
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%) scale(1);
    transform-origin: center;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.2);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all ease-out .5s; -ms-transition: all ease-out .5s; transition: all ease-out .5s;
}
.board-list-video__con{
    padding: 10px 0 16px;
    text-align: left;
}
.board-list-video__tit-wrap{
    position: relative;
}
.board-list-video__tit{
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.2;
}
.board-list-video__more{
    display:none;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translate(0,-50%); -ms-transform: translate(0,-50%); transform: translate(0,-50%);
    align-items: center;
    font-size: 1.4rem;
    color: var(--v-primary-base);
}
.board-list-video__txt{
    font-size: 1.4rem;
    color: #999;
    padding-top: 8px;
    margin-top: 8px;
    border-top: 1px solid #ccc;
}
.board-list-video__html, .board-list-video__html> *{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.board-list-video__html{
    display: block;
    height: 1.6em;
    line-height: 1.6;
}
.board-list-video__html >*{
    display: inline-block;
    max-width: 100%;
}
.board-list-video .board-empty-message{
    padding: 60px 20px;
    border-top: 2px solid #222;
    border-bottom: 1px solid #ddd;
}
@media (min-width:576px){
}
@media (min-width:768px){
    .board-list-video__more{
        display:flex;
    }
    .board-list-video__txt{
        font-size: 1.6rem;
    }
    .board-list-video .board-empty-message{
        padding: 20px 10px;
    }
}
@media (min-width:1024px){
    .board-list-video .row{ margin:-20px -12px; }
    .board-list-video .row [class*="col"]{padding:20px 12px;}
    .board-list-video__con{
        padding:18px 0 26px;
    }
    .board-list-video__img-wrap::before{
        width: 56px;
        height: 56px;
    }
    .board-list-video__inner >a:hover .board-list-video__img::before{
        opacity: 1;
        visibility: visible;
    }
    .board-list-video__inner >a:hover .board-list-video__img{
        opacity: 1;
        visibility: visible;
        transform: translate(-50%,-50%) scale(1.2);
    }
    .board-list-video__tit{
        font-size: 1.8rem;
    }
    .board-list-video__txt{
        font-size: 1.8rem;
        padding-top: 14px;
        margin-top: 14px;
    }
    .board-list-video__more{
        font-size: 1.6rem;
    }
    .board-list-video .board-empty-message{
        padding: 40px 20px;
    }
}
@media (min-width:1200px){
}
